import { useState, useEffect } from 'react';
import debounce from 'lodash/debounce';

const useWindowWidth = (initWidth?: number) => {
  const [width, setWidth] = useState(initWidth || 0);
  const [height, setHeight] = useState(0);

  const handleResize = debounce(() => {
    setWidth(window.innerWidth);
  }, 100);

  useEffect(() => {
    let isMounted = true;
    if (typeof window !== undefined) {
      setHeight(window.innerHeight);
      if (isMounted) {
        window.addEventListener('resize', handleResize);
      }
    }

    return () => {
      if (typeof window !== undefined) {
        isMounted = false;
        window.removeEventListener('resize', handleResize);
      }
    };
  }, [handleResize]);

  return { width, height, handleResize };
};

export default useWindowWidth;
