import { breakpoints } from '@playdapp/ui';

const viewports = {
  EXTRA_LARGE: breakpoints.values('xl'),
  LARGE: breakpoints.values('lg'),
  MEDIUM: breakpoints.values('md'),
  SMALL: breakpoints.values('sm'),
};

export default viewports;
