type Custom = {
  delay?: number;
  y?: number;
  x?: number;
};

const variants = {
  visible: (custom: Custom) => ({
    opacity: 1,
    y: 0,
    x: 0,
    transition: {
      delay: custom.delay || 0.3,
      duration: 0.5,
    },
  }),
  hidden: (custom: Custom) => ({
    opacity: 0,
    y: custom.y || 0,
    x: custom.x || 0,
  }),
};

export default variants;
