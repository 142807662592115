import { useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import styled from '@emotion/styled';
import { Skeleton } from '@chakra-ui/react';
import { breakpoints, Button, fontStyle, palette, spacing } from '@playdapp/ui';

import type { Collection } from 'types/home';
import type SwiperProps from 'types/swiper';

import sliderOptions from 'data/sliderOptions';
import { getBreakpointQuery } from 'lib/util';

import * as HomeStyle from 'styles/pages/home';

import CustomSwiper from '@/components/CustomSwiper';
import Image from '@/components/Image';
import Icon, { Icons } from '@/components/Icon';
import Redirect from '@/components/Redirect';

type Props = {
  collections: Collection[];
  isLoading: boolean;
};

const CollectionsSection = styled.section`
  position: relative;
  width: 100%;
  max-width: 1560px;
  margin: 74px auto 0 auto;

  ${breakpoints.down('lg')} {
    margin: 3.5rem auto 0 auto;
  }

  ${breakpoints.down('md')} {
    margin: 3rem auto 0 auto;
  }
`;

const CollectionsListBox = styled.div`
  position: relative;
  display: flex;
  margin-top: ${spacing.xl};

  .swiper-wrapper {
    transform-style: preserve-3d;
    height: auto;
    display: flex;
  }

  ${breakpoints.down('xl')} {
    .swiper-pagination {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 16px;
      height: 12px;
    }

    .swiper-pagination-bullet {
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      flex: 1;
      width: 12px;
      max-width: 12px;
      height: 12px;
      border-radius: 0.375rem;
      background-color: ${palette.gray400};
      opacity: 0.3;
      cursor: pointer;

      margin-right: ${spacing.m};

      &:last-of-type {
        margin-right: 0;
      }

      ${breakpoints.down('md')} {
        min-width: auto;
        width: 100%;
      }
    }

    .swiper-pagination-bullet-active {
      position: relative;
      flex: 1;
      max-width: 12px;
      opacity: 1;
      background-color: ${palette.dgray100};

      ${breakpoints.down('md')} {
        min-width: auto;
        width: 100%;
      }

      div {
        position: absolute;
        height: 0.375rem;
        opacity: 0.3;
        background-color: #ffffff;
        border-radius: 0.375rem;
      }

      b {
        position: absolute;
        width: 12px;
        height: 12px;
        background-color: ${palette.gray700};
        border-radius: 0.375rem;

        &.play-stop {
          animation-play-state: paused;
        }
      }
    }
  }

  .swiper-slide {
    width: calc((100% / 6) - 12px);

    ${breakpoints.down('xl')} {
      width: calc(25% - 30px);
    }

    ${breakpoints.down('lg')} {
      width: calc((100% / 4) - 30px);
    }

    ${breakpoints.down('md')} {
      width: calc((100% / 3) - 40px);
    }

    ${breakpoints.down('sm')} {
      width: calc(50% - 40px);
    }
  }
`;

const CollectionThumbnail = styled.figure`
  min-width: 180px;

  ${breakpoints.down('md')} {
    min-width: 153px;
  }

  ${breakpoints.down('sm')} {
    min-width: 112px;
  }

  &,
  * {
    max-height: 96px;
    border-radius: 8px;
    object-fit: cover;

    ${breakpoints.down('md')} {
    }

    ${breakpoints.down('sm')} {
    }
  }
`;

const CollectionWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  ${breakpoints.down('md')} {
    margin-bottom: ${spacing.xxs};
  }
`;

const CollectionInfoBox = styled.div`
  display: block;
  max-width: 312px;
  margin-top: ${spacing.xxs};

  ${breakpoints.down('lg')} {
    max-width: 100%;
  }
`;

const CollectionName = styled.span`
  margin-left: ${spacing.xs};
  color: ${palette.black};
  ${fontStyle('b3')};
  text-overflow: ellipsis;
  white-space: normal;
  overflow: hidden;

  ${breakpoints.down('lg')} {
    margin-right: ${spacing.xxs};
  }

  ${breakpoints.down('md')} {
    ${fontStyle('b4')};
  }
`;

const CollectionDescription = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  ${fontStyle('p4')};
  color: ${palette.gray800};

  ${breakpoints.down('lg')} {
    font-size: 12px;
    font-weight: 400;
  }
`;

const SwiperNavigation = styled.div<{
  isNext: boolean;
  isMdDown: boolean;
  isCollectionHover: boolean;
  collectionCount: boolean;
}>`
  display: ${({ collectionCount }) => collectionCount && 'none'};
  position: absolute;
  z-index: 1;
  top: 50%;
  right: ${({ isNext }) => (isNext ? '-17px' : 'calc(101% - 38px)')};
  transform: translate(0, -50%);

  .swiper-button-next2 {
    display: ${({ isMdDown, collectionCount }) =>
      isMdDown && collectionCount ? 'none' : 'flex'};
    opacity: ${({ isCollectionHover }) => (!isCollectionHover ? 0 : 1)};
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    border-radius: 8px;
    background-color: ${palette.gray200};
    box-shadow: 0px 4px 8px 0px #1e1f2426;
    cursor: pointer;
    transition: opacity 0.3s;

    &:hover {
      background-color: ${palette.gray200};
    }

    ${breakpoints.down('xl')} {
      display: none;
    }
  }

  .swiper-button-prev2 {
    display: ${({ isMdDown, collectionCount }) =>
      isMdDown && collectionCount ? 'none' : 'flex'};
    opacity: ${({ isCollectionHover }) => (!isCollectionHover ? 0 : 1)};
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    border-radius: 8px;
    background-color: ${palette.gray200};
    box-shadow: 0px 4px 8px 0px #1e1f2426;
    cursor: pointer;
    transition: opacity 0.3s;

    &:hover {
      background-color: ${palette.gray200};
    }

    ${breakpoints.down('xl')} {
      display: none;
    }
  }
`;

const CollectionItemBlock = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: ${spacing.m};
`;

const ViewAllBlock = styled.div`
  display: flex;
  align-items: center;

  button {
    display: flex;
    align-items: baseline;
    justify-content: center;
    border-radius: 8px;
    padding: 6px 16px;
    gap: 12px;

    ${breakpoints.down('md')} {
      padding: 6px 12px;
      gap: 10px;
    }
  }
`;

const HomeCollections = ({ collections, isLoading }: Props) => {
  const [slide, setSlide] = useState(0);
  const [activeNavigation, setActiveNavigation] = useState(false);
  const [isCollectionHover, setIsCollectionHover] = useState(false);
  const isXlDownScreen = useMedia(
    getBreakpointQuery(breakpoints.down('xl')),
    true,
  );

  const isMdDownScreen = useMedia(
    getBreakpointQuery(breakpoints.down('md')),
    true,
  );

  const trendingSlide = () => {
    if (isMdDownScreen && slide < 2) {
      setSlide(1);
      setActiveNavigation(false);
    }

    if (!isMdDownScreen && isXlDownScreen) {
      setSlide(2);
      setActiveNavigation(true);
    }

    if (!isMdDownScreen && !isXlDownScreen) {
      setSlide(3);
      setActiveNavigation(true);
    }
  };

  const handleMouseOver = (isHover: boolean) => () => {
    setIsCollectionHover(isHover);
  };

  useEffect(() => {
    trendingSlide();
  }, [trendingSlide, isMdDownScreen, isXlDownScreen]);

  const collectionSlideOptions: SwiperProps = {
    ...sliderOptions,
    slidesPerView: slide,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      renderBullet: (
        index,
        className,
      ) => `<div key='main-collection-${index}' class='${className}'>
      <div></div>
      <b></b>
    </div>`,
    },
    navigation: {
      nextEl: '.swiper-button-next2',
      prevEl: '.swiper-button-prev2',
    },
    touchRatio: isMdDownScreen ? 1 : 0,
    loop: isMdDownScreen && true,
    spaceBetween: 12,
    autoplay: false,
  };

  return (
    <CollectionsSection>
      <HomeStyle.SectionTitleBlock>
        <HomeStyle.SectionTitle>Trending Collection</HomeStyle.SectionTitle>

        <Redirect to="/nfts/all/all/collections">
          <ViewAllBlock>
            <Button
              size={isMdDownScreen ? 'sm' : 'md'}
              variant="ghost"
              color="secondary"
            >
              View all
              <Icon
                name="chevron-right-nopadding"
                size={isMdDownScreen ? '8px' : '12px'}
              />
            </Button>
          </ViewAllBlock>
        </Redirect>
      </HomeStyle.SectionTitleBlock>

      <CollectionsListBox
        onMouseEnter={handleMouseOver(true)}
        onMouseLeave={handleMouseOver(false)}
      >
        <CustomSwiper options={collectionSlideOptions}>
          {isLoading
            ? [1, 2, 3, 4, 5].map((item) => (
                <CustomSwiper.Item key={`collections-${item}`}>
                  <CollectionItemBlock>
                    <CollectionThumbnail>
                      <Skeleton width="100%" height="100%" borderRadius="12px">
                        <Image
                          src="https://images.playdapp.com/marketplace-dev/home/sub-frenz.png"
                          alt=""
                          layout="responsive"
                          width={552}
                          height={552}
                        />
                      </Skeleton>
                    </CollectionThumbnail>
                    <CollectionInfoBox>
                      <CollectionName>
                        <Skeleton width="100px" height="20px" />
                      </CollectionName>
                      <Skeleton width="20px" height="20px" borderRadius="50%" />
                    </CollectionInfoBox>
                  </CollectionItemBlock>
                </CustomSwiper.Item>
              ))
            : collections
                .filter((item) => item.network !== 'klaytn')
                .map((item, index) => (
                  <CustomSwiper.Item
                    key={`main-collections-${index}`}
                    className={`main-collections-${index}`}
                  >
                    <Redirect
                      to={`/collections/${item.network}/${item.categoryId}`}
                    >
                      <CollectionItemBlock>
                        <CollectionThumbnail>
                          <Image
                            src={item.thumbnailUrl}
                            alt={item.thumbnailUrl}
                            layout="responsive"
                            width={isMdDownScreen ? 153 : 180}
                            height={96}
                          />
                        </CollectionThumbnail>
                        <CollectionInfoBox>
                          <CollectionWrapper>
                            <Icon
                              name={item.network.toLowerCase() as Icons}
                              alt={`${item.title}-${item.network}`}
                              size={18}
                            />
                            <CollectionName>{item.title}</CollectionName>
                          </CollectionWrapper>
                          <CollectionDescription>
                            {item.shortDescription}
                          </CollectionDescription>
                        </CollectionInfoBox>
                      </CollectionItemBlock>
                    </Redirect>
                  </CustomSwiper.Item>
                ))}
          <div className="swiper-pagination" />
        </CustomSwiper>

        <SwiperNavigation
          isNext={false}
          isCollectionHover={isCollectionHover}
          isMdDown={isMdDownScreen}
          collectionCount={activeNavigation}
        >
          <div className="swiper-button-prev2">
            <Icon name="arrow-left-v2" alt="arrow-left" size={12} />
          </div>
        </SwiperNavigation>
        <SwiperNavigation
          isNext
          isCollectionHover={isCollectionHover}
          isMdDown={isMdDownScreen}
          collectionCount={activeNavigation}
        >
          <div className="swiper-button-next2">
            <Icon name="arrow-right-v2" alt="arrow-right" size={12} />
          </div>
        </SwiperNavigation>
      </CollectionsListBox>
    </CollectionsSection>
  );
};
export default HomeCollections;
