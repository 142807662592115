import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  breakpoints,
  spacing,
  palette,
  fontSize,
  fontStyle,
} from '@playdapp/ui';
import { motion } from 'framer-motion';

export const MainContentBlock = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
`;

export const MainHeaderBlock = styled(motion.div)<{
  isAdvertise: boolean;
  bannerBackground: string;
}>`
  position: absolute;
  width: 100%;
  height: 100%;
  max-height: ${({ theme }) => theme.rem(705)};
  z-index: -1;
  background: ${({ isAdvertise, bannerBackground }) =>
    !isAdvertise && bannerBackground};
  top: 0;
  left: 0;
  right: 0;
`;

export const BannerContainer = styled.div`
  min-height: 705px;

  ${breakpoints.down('xl')} {
    min-height: 616px;
  }

  ${breakpoints.down('lg')} {
    min-height: 516px;
  }

  ${breakpoints.down('md')} {
    display: flex;
    flex-direction: row-reverse;
    min-height: 452px;
  }
`;

export const BannerSection = styled.div<{ isFinalLoading: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: ${({ theme }) => theme.rem(1560)};
  max-height: ${({ theme }) => theme.rem(705)};
  gap: 160px;
  margin: auto;
  padding: 120px 32px 24px 32px;

  ${breakpoints.down('xl')} {
    gap: 50px;
  }

  ${breakpoints.down('lg')} {
    gap: 25px;
  }

  ${breakpoints.down('md')} {
    flex-direction: ${({ isFinalLoading }) =>
      isFinalLoading && 'column-reverse'};
    padding-top: 64px;
  }
`;

export const MainBanner = styled.div`
  position: relative;
  width: 100%;
  width: ${({ theme }) => theme.rem(509)};
  height: ${({ theme }) => theme.rem(555)};
  top: 4%;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);

  .swiper {
    height: 100%;
  }

  .swiper-slide {
    height: auto;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
    margin-right: 0.5px;

    opacity: 1 !important;
    &:not(.swiper-slide-active) {
      opacity: 0 !important;
    }
  }

  .swiper-wrapper {
    transform-style: preserve-3d;
  }

  .swiper-pagination {
    display: flex;
    justify-content: center;
    position: relative;
    bottom: ${spacing.xl};
    width: 90% !important;
    margin: auto;
    z-index: 1;

    .swiper-pagination-bullet {
      background-color: transparent;
    }

    .swiper-pagination-bullet-active {
      display: flex;
      position: relative;
      flex: 1;
      min-width: 100%;
      max-width: 100%;
      height: 0.375rem;
      background-color: transparent;
      opacity: 1;

      ${breakpoints.down('md')} {
        min-width: auto;
        width: 100%;
      }

      div {
        position: absolute;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        height: 0.375rem;
        opacity: 0.3;
        background-color: #ffffff;
        border-radius: 0.375rem;
      }

      b {
        position: absolute;
        width: 100%;
        height: 4px;
        background-color: ${palette.gray700};
        border-radius: 0.375rem;
        animation-name: paginationBar;
        animation-duration: 3.7s;
        animation-timing-function: ease-in;
        animation-iteration-count: 1;
        animation-direction: alternate;
        animation-fill-mode: forwards;
        animation-play-state: running;

        &.play-stop {
          animation-play-state: paused;
        }
      }
    }

    @keyframes paginationBar {
      0% {
        width: 0;
      }
      100% {
        width: 100%;
      }
    }

    ${breakpoints.down('lg')} {
      bottom: ${spacing.xs};
    }

    ${breakpoints.down('md')} {
      width: 100% !important;
    }
  }

  ${breakpoints.down('xl')} {
    width: ${({ theme }) => theme.rem(440)};
    height: ${({ theme }) => theme.rem(480)};
  }

  ${breakpoints.down('lg')} {
    width: ${({ theme }) => theme.rem(360)};
    height: ${({ theme }) => theme.rem(380)};
  }

  ${breakpoints.down('md')} {
    width: 100%;
    height: 100%;
    margin: auto;

    .swiper-pagination {
      bottom: 0;
    }
  }
`;

export const BannerTextBlock = styled(motion.div)`
  width: ${({ theme }) => theme.rem(507)};
  height: auto;

  ${breakpoints.down('lg')} {
    width: 100%;
    margin-top: ${spacing.xl};
  }
`;

export const BannerTextContainer = styled.div`
  display: block;
`;

export const BannerTitle = styled(motion.div)`
  font-size: 48px;
  font-weight: 800;
  margin-bottom: ${spacing.m};

  ${breakpoints.down('lg')} {
    font-size: 32px;
  }

  ${breakpoints.down('md')} {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: normal;
    text-align: initial;
    ${fontStyle('h4')};
    margin-bottom: ${spacing.xs};
  }
`;

export const BannerDescription = styled(motion.div)`
  ${fontStyle('b1')};
  color: ${palette.dgray100};
  margin-bottom: ${spacing.s};
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  text-align: initial;
  margin-bottom: 48px;

  ${breakpoints.down('lg')} {
    ${fontStyle('b2')};
  }

  ${breakpoints.down('md')} {
    max-height: 70px;
    ${fontStyle('p3')};
  }
`;

export const BannerButtonBlock = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: ${spacing.xs};

  ${breakpoints.down('md')} {
    margin-bottom: ${spacing.xl};
  }

  button {
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const SwiperNavigation = styled.div`
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 255px;

  ${breakpoints.down('xxl')} {
    left: 24px;
  }

  .swiper-button-prev {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.25rem;
    height: 2.25rem;
    border-radius: 24px;
    background: rgba(255, 255, 255, 0.3);
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    &:hover {
      background: rgba(255, 255, 255, 0.5);
    }

    &:active {
      background: rgba(255, 255, 255, 0.4);
    }
  }

  ${breakpoints.down('md')} {
    .swiper-button-prev {
      width: 2rem;
      height: 2rem;
    }
  }

  ${breakpoints.down('sm')} {
    display: none;
  }
`;

export const SwiperNavigationNext = styled.div`
  position: absolute;
  z-index: 1;
  top: 50%;
  right: 255px;

  ${breakpoints.down('xxl')} {
    right: 24px;
  }

  .swiper-button-next {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.25rem;
    height: 2.25rem;
    border-radius: 24px;
    background: rgba(255, 255, 255, 0.3);
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    &:hover {
      background: rgba(255, 255, 255, 0.5);
    }

    &:active {
      background: rgba(255, 255, 255, 0.4);
    }
  }

  ${breakpoints.down('md')} {
    .swiper-button-next {
      width: 2rem;
      height: 2rem;
    }
  }

  ${breakpoints.down('sm')} {
    display: none;
  }
`;

export const BannerImageBlock = styled.div<{ backgroundColor: string | null }>`
  position: relative;
  width: ${({ theme }) => theme.rem(509)};
  height: ${({ theme }) => theme.rem(509)};
  margin: auto;

  ${breakpoints.down('xl')} {
    width: ${({ theme }) => theme.rem(440)};
    height: ${({ theme }) => theme.rem(440)};
  }

  ${breakpoints.down('lg')} {
    max-width: ${({ theme }) => theme.rem(360)};
    max-height: ${({ theme }) => theme.rem(360)};
  }

  ${breakpoints.down('md')} {
    max-width: ${({ theme }) => theme.rem(320)};
    max-height: ${({ theme }) => theme.rem(320)};
  }

  ${breakpoints.down('sm')} {
    max-width: ${({ theme }) => theme.rem(296)};
    max-height: ${({ theme }) => theme.rem(296)};
  }

  span {
    border-radius: 20px;
  }

  video {
    border-radius: 20px;
  }

  ${({ backgroundColor }) =>
    backgroundColor &&
    css`
      background-color: ${backgroundColor};
    `}
`;

export const NotificationSection = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: ${({ theme }) => theme.rem(58)};
  background-color: ${palette.primary100};
  box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.02);
  transition: box-shadow 0.2s ease-in-out;

  &:hover,
  &:active {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }

  a {
    width: 100%;
  }

  ${breakpoints.down('md')} {
    display: none;
  }
`;

export const NotificationBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: ${({ theme }) => theme.rem(1506)};
  padding: ${spacing.m} 3rem;
  margin: 0 auto;

  cursor: pointer;
  transition: box-shadow 0.2s;

  ${breakpoints.down('xl')} {
    padding: ${spacing.m} 3rem;
  }

  ${breakpoints.down('lg')} {
    padding: ${spacing.m} ${spacing.xxl};
  }
`;

export const NotificationContents = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const NotificationLabel = styled.div<{ bgColor?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.75rem;
  padding: ${spacing.xxs} ${spacing.xs};
  border-radius: 0.25rem;
  font-weight: 600;
  font-size: 12px;
  color: #0aa4e7;
  background-color: ${({ bgColor }) => bgColor || 'inherit'};
`;

export const NotificationText = styled.div`
  width: 100%;
  margin-left: ${spacing.m};
  color: ${palette.black};

  b {
    font-weight: 600;
  }

  span.gray {
    color: ${palette.gray900};
  }
`;

export const NotificationDate = styled.div`
  min-width: 80px;
  margin-left: ${spacing.m};
  font-size: ${fontSize.p5};
  color: #696a75;
  cursor: pointer;
`;

export const DropsSlideSection = styled.div`
  display: flex;
  align-items: center;
  padding: 0 32px;

  ${breakpoints.down('xl')} {
    padding: 0 2rem;
  }

  ${breakpoints.down('md')} {
    padding: 0;
  }
`;

export const CollectionSlideSection = styled.div`
  display: flex;
  align-items: center;
  padding: 0 32px;

  ${breakpoints.down('xl')} {
    padding: 0 2rem;
  }

  ${breakpoints.down('md')} {
    padding: 0 1.5rem;
  }
`;

export const BottomContentBlock = styled.div`
  display: flex;
  align-items: center;
  padding: 0 32px;

  ${breakpoints.down('xl')} {
    padding: 0 2rem;
  }

  ${breakpoints.down('md')} {
    padding: 0 1.5rem;
  }
`;

export const FeaturedNftSections = styled.div`
  width: 100%;
  max-width: ${({ theme }) => theme.rem(1560)};
  margin: 4rem auto 3rem auto;

  ${breakpoints.down('xl')} {
    margin: 3.5rem auto 4rem auto;
  }

  ${breakpoints.down('md')} {
    margin: 56px 0 3rem 0;
  }
`;

export const FeatureTitleBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FeaturedNftContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  gap: ${spacing.l};
  grid-auto-flow: dense;
  margin-top: ${spacing.xl};

  ${breakpoints.down('xxl')} {
    grid-template-columns: repeat(5, minmax(0, 1fr));
    gap: ${spacing.m};
  }

  ${breakpoints.down('xl')} {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    row-gap: ${spacing.l};
    column-gap: ${spacing.xl};
  }

  ${breakpoints.down('lg')} {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: ${spacing.l};
    margin-top: ${spacing.l};
  }

  ${breakpoints.down('md')} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: ${spacing.m};
  }

  @media screen and (max-width: 359px) {
    grid-template-columns: minmax(0, 1fr);
    gap: ${spacing.s};
  }
`;

export const SectionTitleBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    align-items: center;
  }
`;

export const SectionTitle = styled.div`
  font-size: ${({ theme }) => theme.rem(32)};
  font-weight: 600;

  ${breakpoints.down('lg')} {
    font-size: ${({ theme }) => theme.rem(24)};
  }

  ${breakpoints.down('md')} {
    ${fontStyle('h4')};
  }

  ${breakpoints.down('sm')} {
    font-size: ${({ theme }) => theme.rem(20)};
  }
`;

export const GettingStartedSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: ${spacing.xxxl} 0 5rem 0;
  margin: 0 auto;
  background-color: ${palette.gray300};

  ${breakpoints.down('lg')} {
    padding: ${spacing.l} 0 ${spacing.xxxl} 0;

    h3 {
      font-size: ${fontSize.h4};
    }
  }

  ${breakpoints.down('md')} {
    padding: ${spacing.l} 0;

    h3 {
      font-size: ${fontSize.h4};
    }
  }

  @media screen and (max-width: 479px) {
    padding: ${spacing.l} 0 ${spacing.m} 0;

    h3 {
      font-size: ${fontSize.h5};
    }
  }
`;

export const ViewAllBlock = styled.div`
  display: flex;
  align-items: center;

  button {
    display: flex;
    align-items: baseline;
    justify-content: center;
    border-radius: 8px;
    padding: 6px 16px;
    gap: 12px;

    ${breakpoints.down('md')} {
      padding: 6px 12px;
      gap: 10px;
    }
  }
`;

export const BannerLinkButton = styled(motion.button)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  padding: 10px 24px;
  ${fontStyle('p2')};
  background-color: ${palette.primary600};
  color: white;
  transition: 0.3s;

  ${breakpoints.down('md')} {
    height: 32px;
    padding: 6px 16px;
    ${fontStyle('p4')};
  }

  :hover {
    background-color: ${palette.primary700};
  }
`;

export const GettingStartTitle = styled.div`
  font-size: 32px;
  font-weight: 600;
`;

export const GettingStartedListBlock = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 1560px;
  margin-top: ${spacing.xl};

  a + a {
    margin-left: ${spacing.l};
  }

  ${breakpoints.down('xxl')} {
    padding: 0 ${spacing.xxl};
  }

  ${breakpoints.down('lg')} {
    a + a {
      margin-left: ${spacing.m};
    }
  }

  ${breakpoints.down('md')} {
    flex-direction: column;
    align-items: center;

    a + a {
      margin-left: 0;
      margin-top: ${spacing.m};
    }
  }
`;

export const GettingStartedItemBlock = styled.a`
  overflow: hidden;
  width: 100%;
  border-radius: 16px;
  box-shadow: 0px 4px 4px rgba(21, 22, 25, 0.2);
  backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
`;

export const GettingStartedItemImageBlock = styled.div`
  position: relative;
  width: 100%;
`;

export const GettingStartedItemTextBlock = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${palette.white};
  padding: ${spacing.m} ${spacing.m} ${spacing.l} ${spacing.m};
`;

export const GettingStartedItemText = styled.p`
  line-height: 1.5;
  font-size: ${fontSize.b4};
  font-weight: 600;

  ${breakpoints.down('xl')} {
    font-size: ${fontSize.b5};
  }
`;

export const AvailableBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${palette.gray400};
  padding: 32px;

  ${breakpoints.down('xl')} {
    flex-direction: column;
  }

  ${breakpoints.down('lg')} {
    padding: ${spacing.xl};

    & > p {
      font-size: ${fontSize.p5};
    }
  }

  ${breakpoints.down('md')} {
    padding: ${spacing.m} ${spacing.xl};

    & > p {
      font-size: ${fontSize.p6};
    }
  }
`;

export const AvailableExchangeListBlock = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 4rem;
  flex-wrap: wrap;

  ${breakpoints.down('xl')} {
    margin-left: 0;
    margin-top: ${spacing.l};
  }

  ${breakpoints.down('lg')} {
    row-gap: ${spacing.l};
    column-gap: ${spacing.xl};
  }

  @media screen and (max-width: 479px) {
    a > span,
    a > span > img {
      width: 64px !important;
      height: 12px !important;
    }
  }
`;

export const AvailableExchangeLink = styled.a`
  height: 19px;
  display: flex;
  align-items: center;

  img {
    object-fit: contain;
  }

  @media screen and (max-width: 479px) {
    height: 17px;
  }
`;
