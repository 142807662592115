import styled from '@emotion/styled';
import { breakpoints, fontSize, palette, spacing } from '@playdapp/ui';

import Button from '@/components/Button';

type Props = {
  backgroundColor?: string;
};

const PageBlock = styled.div<Props>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : '#fafafa'};
`;

const Action = styled.div`
  margin-top: ${({ theme }) => theme.rem(48)};

  ${breakpoints.down('lg')} {
    margin-top: ${({ theme }) => theme.rem(36)};
  }

  ${breakpoints.down('md')} {
    margin-top: ${spacing.xl};
  }
`;

const TextBox = styled.div<{
  xl: string;
  lg: string;
  md: string;
  fontWeight: number;
}>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  text-align: center;
  font-size: ${({ xl }) => xl};
  font-weight: ${({ fontWeight }) => fontWeight};
  color: ${palette.black};

  &:nth-of-type(2) {
    margin-top: ${spacing.xl};
  }

  ${breakpoints.down('lg')} {
    font-size: ${({ lg }) => lg};

    &:nth-of-type(2) {
      margin-top: ${spacing.m};
    }
  }

  ${breakpoints.down('md')} {
    font-size: ${({ md }) => md};
  }
`;

const ErrorContents = ({ backgroundColor }: Props) => (
  <PageBlock backgroundColor={backgroundColor}>
    <TextBox
      xl={fontSize.h2}
      lg={fontSize.h3}
      md={fontSize.h4}
      fontWeight={900}
    >
      ERROR
    </TextBox>
    <TextBox
      xl={fontSize.p1}
      lg={fontSize.p3}
      md={fontSize.p4}
      fontWeight={400}
    >
      An error has occurred.
    </TextBox>
    <TextBox
      xl={fontSize.p1}
      lg={fontSize.p3}
      md={fontSize.p4}
      fontWeight={400}
    >
      Please contact &quot;Customer support&quot;
    </TextBox>
    <Action>
      <Button to="https://playdapp.atlassian.net/servicedesk/customer/portals">
        Customer Support
      </Button>
    </Action>
  </PageBlock>
);

export default ErrorContents;
