export const gettingStartedItems = [
  {
    title: 'How to set up a wallet',
    imageSrc: '/home/bg-easily-wallet-pda.png',
    link: 'https://medium.com/playdappgames/playdapp-marketplace-wallet-setup-guide-67bfce868087',
    target: '_blank',
  },
  {
    title: 'Trader’s Guide',
    imageSrc: '/home/bg-trader-guide.png',
    link: 'https://medium.com/playdappgames/playdapp-marketplace-quick-guide-ad35533ccef1',
    target: '_blank',
  },
  {
    title: 'Frequently Asked Question',
    imageSrc: '/home/bg-faq.png',
    link: '/faq/marketplace',
    target: '_self',
  },
];

export const exchangeList = [
  {
    brand: 'binance03171421',
    imageWidth: '100px',
    imageHeight: '19px',
    link: 'https://www.binance.com/en',
  },
  {
    brand: 'coinbase03171421',
    imageWidth: '100px',
    imageHeight: '21px',
    link: 'https://www.coinbase.com/price/playdapp',
  },
  {
    brand: 'upbit03171421',
    imageWidth: '80px',
    imageHeight: '21px',
    link: 'https://www.upbit.com/exchange?code=CRIX.UPBIT.KRW-PLA',
  },
  {
    brand: 'kraken03171421',
    imageWidth: '100px',
    imageHeight: '21px',
    link: 'https://www.kraken.com/prices/pla-playdapp-price-chart/usd-us-dollar?interval=1m',
  },
  {
    brand: 'bittrex03171421',
    imageWidth: '100px',
    imageHeight: '21px',
    link: 'https://global.bittrex.com/Market/Index?MarketName=EUR-PLA',
  },
  {
    brand: 'gateio03171421',
    imageWidth: '100px',
    imageHeight: '21px',
    link: 'https://www.gate.io',
  },
  {
    brand: 'cryptocom03171421',
    imageWidth: '100px',
    imageHeight: '21px',
    link: 'https://crypto.com',
  },
  {
    brand: 'huobi03171421',
    imageWidth: '89px',
    imageHeight: '21px',
    link: 'https://www.huobi.com',
  },
];
