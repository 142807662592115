import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { breakpoints, spacing, palette, fontStyle } from '@playdapp/ui';

export const MainContentBlock = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
`;

export const MainHeaderBlock = styled.div<{ isAdvertise: boolean }>`
  background: ${({ isAdvertise }) =>
    isAdvertise &&
    'linear-gradient(180deg, #cee8ff 0%, rgba(255, 255, 255, 0) 70%)'};
`;

export const BannerSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 50px;
  margin: auto;

  ${breakpoints.down('lg')} {
    gap: 25px;
  }

  ${breakpoints.down('md')} {
    display: block;
  }
`;

export const BannerWrapper = styled.div`
  width: 100%;
`;

export const MainBanner = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);

  .swiper {
    height: 100%;
  }

  .swiper-slide {
    height: auto;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
    margin-right: 0.5px;
  }

  .swiper-wrapper {
    transform-style: preserve-3d;
  }

  .swiper-pagination {
    display: flex;
    justify-content: center;
    position: relative;
    bottom: 0;
    bottom: ${spacing.xl};
    width: 90% !important;
    margin: auto;
    z-index: 1;
    background-color: ${palette.gray400};

    .swiper-pagination-bullet {
      box-sizing: border-box;
      flex: 1;
      height: 0.375rem;
      border-radius: 0.375rem;
      background-color: ${palette.gray400};
      opacity: 0.3;
      cursor: pointer;

      margin-right: ${spacing.m};

      &:last-of-type {
        margin-right: 0;
      }

      ${breakpoints.down('md')} {
        min-width: auto;
        width: 100%;
      }
    }

    .swiper-pagination-bullet-active {
      position: relative;
      flex: 1;
      min-width: 4.75rem;
      max-width: 9.5rem;
      height: 0.375rem;
      opacity: 1;
      background-color: ${palette.gray700};

      ${breakpoints.down('md')} {
        min-width: auto;
        width: 100%;
      }

      div {
        position: absolute;
        width: 100%;
        max-width: 9.5rem;
        height: 0.375rem;
        opacity: 0.3;
        background-color: #ffffff;
        border-radius: 0.375rem;
      }

      b {
        position: absolute;
        width: 100%;
        height: 0.375rem;
        background-color: ${palette.gray700};
        border-radius: 0.375rem;

        &.play-stop {
          animation-play-state: paused;
        }
      }
    }

    @keyframes paginationBar {
      0% {
        width: 0;
      }
      100% {
        width: 100%;
      }
    }

    ${breakpoints.down('md')} {
      width: 100% !important;
    }
  }

  ${breakpoints.down('lg')} {
    height: ${({ theme }) => theme.rem(480)};
    max-width: ${({ theme }) => theme.rem(480)};
  }

  ${breakpoints.down('md')} {
    height: 100%;
    margin: auto;

    .swiper-pagination {
      bottom: 0.75rem;
    }
  }
`;

export const BannerImageBlock = styled.div<{ backgroundColor: string | null }>`
  position: relative;
  width: 100%;
  height: 705px;
  margin: auto;

  ${breakpoints.down('xl')} {
    height: ${({ theme }) => theme.rem(616)};
  }

  ${breakpoints.down('lg')} {
    height: ${({ theme }) => theme.rem(452)};
  }

  ${breakpoints.down('md')} {
    height: ${({ theme }) => theme.rem(632)};
  }

  ${breakpoints.down('sm')} {
    height: ${({ theme }) => theme.rem(572)};
  }

  span {
    border-radius: 20px;
  }

  video {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover !important;
  }

  ${({ backgroundColor }) =>
    backgroundColor &&
    css`
      background-color: ${backgroundColor};
    `}
`;

export const BannerBlock = styled.div`
  position: absolute;
  width: 100%;
  color: white;
  bottom: 0;
  padding: 0 32px;
  margin-bottom: 56px;
  text-align: left;

  ${breakpoints.down('md')} {
    padding: 0 24px;
    margin-bottom: 32px;
  }

  button {
    border-radius: 6px;
  }
`;

export const BannerInfoWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1560px;
  margin: 0 auto;
  gap: 48px;

  ${breakpoints.down('lg')} {
    padding: 0;
  }

  ${breakpoints.down('sm')} {
    display: block;
  }
`;

export const TextBlock = styled.div`
  width: 100%;
  max-width: 1405px;
`;

export const BannerTitle = styled.div`
  font-size: 48px;
  font-weight: 800;

  ${breakpoints.down('lg')} {
    font-size: 32px;
    margin-bottom: ${spacing.xs};
  }
  ${breakpoints.down('md')} {
    font-size: 24px;
  }
  ${breakpoints.down('sm')} {
    font-size: 20px;
  }
`;
export const BannerDesc = styled.div`
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 1;
  word-wrap: break-word;
  -webkit-box-orient: vertical;

  ${fontStyle('p1')}

  ${breakpoints.down('lg')} {
    ${fontStyle('p3')}
  }
  ${breakpoints.down('md')} {
    margin-bottom: 1rem;
  }

  ${breakpoints.down('sm')} {
    ${fontStyle('p4')}
  }
`;
