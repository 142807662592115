import { useState, useMemo, useEffect } from 'react';
import { breakpoints, Button, palette } from '@playdapp/ui';
import { Skeleton } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useMedia } from 'react-use';
import { capitalize } from 'lodash';
import { motion } from 'framer-motion';

import * as Style from 'styles/pages/home';
import * as AdverStyle from 'styles/pages/adverhome';

import type { Banner } from 'types/home';
import type SwiperProps from 'types/swiper';

import { getBreakpointQuery } from 'lib/util';
import sliderOptions from 'data/sliderOptions';
import variants from 'data/variants';

import Icon from '@/components/Icon';
import Image from '@/components/Image';
import Video from '@/components/Video';
import Redirect from '@/components/Redirect';
import CustomSwiper from '@/components/CustomSwiper';

import 'swiper/css/effect-fade';

type BannerProps = {
  isAdvertise: boolean;
  isFinalLoading: boolean;
  mainBannerList: Banner[];
};

const SwiperNavigation = styled.div<{
  isNext: boolean;
  isBannerOver: boolean;
  isMdDownScreen: boolean;
}>`
  position: absolute;
  display: ${({ isMdDownScreen }) => isMdDownScreen && 'none'};
  z-index: 100;
  top: 48%;
  right: ${({ isNext }) => (isNext ? '-3%' : 'calc(103% - 38px)')};
  transform: translate(0, -50%);

  .swiper-button-next2 {
    display: flex;
    opacity: ${({ isBannerOver }) => (!isBannerOver ? 0 : 1)};
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    border-radius: 8px;
    background-color: ${palette.gray200};
    box-shadow: 0px 4px 8px 0px #1e1f2426;
    cursor: pointer;
    transition: opacity 0.3s;

    &:hover {
      background-color: ${palette.gray200};
    }

    ${breakpoints.down('md')} {
      display: none;
    }
  }

  .swiper-button-prev2 {
    display: flex;
    opacity: ${({ isBannerOver }) => (!isBannerOver ? 0 : 1)};
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    border-radius: 8px;
    background-color: ${palette.gray200};
    box-shadow: 0px 4px 8px 0px #1e1f2426;
    cursor: pointer;
    transition: opacity 0.3s;

    &:hover {
      background-color: ${palette.gray200};
    }

    ${breakpoints.down('md')} {
      display: none;
    }
  }
`;

const VisitButton = styled(Button)`
  width: 107px;
  height: 48px;
  border-radius: 10px;
  font-weight: 400;
  font-size: 18px;
  padding: 0;

  ${breakpoints.down('xl')} {
    width: 93px;
    height: 40px;
  }
  ${breakpoints.down('lg')} {
    width: 78px;
    height: 32px;
    font-weight: 400;
    font-size: 14px;
  }
`;

const HomeBanner = ({
  isAdvertise,
  isFinalLoading,
  mainBannerList,
}: BannerProps) => {
  const [isBannerOver, setIsBannerOver] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slideInViewList, setSlideInViewList] = useState<boolean[]>([]);
  const [findY, setFindY] = useState('');
  const [findYArray, setFindYArray] = useState({});

  const isMdUpScreen = useMedia(getBreakpointQuery(breakpoints.up('md')), true);

  const isMdDownScreen = useMedia(
    getBreakpointQuery(breakpoints.down('md')),
    true,
  );

  const effectType = isMdUpScreen ? 'fade' : 'slide';
  const ratioType = isMdUpScreen ? 0 : 1;

  const BannersData = useMemo(
    () =>
      mainBannerList
        .sort((prev, cur) => +prev.sort - +cur.sort)
        .map((item, idx) => {
          const next = item;
          next.sort = idx + 1;
          return next;
        }),
    [mainBannerList, currentSlide],
  );

  const mainSlideOptions: SwiperProps = useMemo(
    () => ({
      ...sliderOptions,
      effect: effectType,
      fadeEffect: { crossFade: isMdUpScreen && true },
      speed: 500,
      autoplay: {
        delay: 3500,
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
      },
      loop: true,
      touchRatio: ratioType,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        renderBullet: (
          index,
          className,
        ) => `<div key='main-banner-${index}' class='${className}'>
        <div></div>
        <b></b>
      </div>`,
      },
      navigation: {
        nextEl: '.swiper-button-next2',
        prevEl: '.swiper-button-prev2',
      },
      maxBackfaceHiddenSlides: 0,
    }),
    [sliderOptions, effectType, ratioType, isMdUpScreen],
  );

  const handleMouseOver = (isHover: boolean) => () => {
    setIsBannerOver(isHover);
  };

  useEffect(() => {
    if (!slideInViewList.length && slideInViewList.length <= currentSlide)
      return;

    const nextSlideInView = slideInViewList.map(
      (item, idx) => idx + 1 === currentSlide,
    );

    setSlideInViewList(nextSlideInView);
  }, [currentSlide]);

  useEffect(() => {
    setSlideInViewList(mainBannerList.map(() => false));
  }, [mainBannerList]);

  useEffect(() => {
    mainBannerList.map((info) => {
      if (info.isAd?.includes('Y')) {
        setFindY(info.bannerId);
      }
      return setFindYArray(info);
    });
  }, [mainBannerList, findYArray, findY]);

  return (
    <div>
      {isAdvertise ? (
        <AdverStyle.BannerSection>
          {!!mainBannerList.length &&
            mainBannerList
              .filter((item) => item?.isAd === 'Y' && item?.bannerId === findY)
              .map((adver, idx) => (
                <AdverStyle.BannerWrapper key={`adver-banner-${idx}`}>
                  <AdverStyle.BannerImageBlock
                    backgroundColor={adver.backgroundColor}
                  >
                    {adver.contentType.toLowerCase() === 'video' && (
                      <Video
                        src={adver.image}
                        poster={adver.thumbnailImage}
                        errorImg={adver.thumbnailImage}
                        autoPlay
                        loop
                        muted
                        playsInline
                        isPlaydapp={false}
                        preload="metadata"
                        isObjectFitCover
                      />
                    )}
                    {adver.contentType.toLowerCase() === 'image' && (
                      <Image
                        src={adver.image}
                        alt={adver.title}
                        layout="fill"
                        objectFit={adver.backgroundColor ? 'contain' : 'cover'}
                        objectPosition="center"
                        quality={100}
                        priority
                        draggable={false}
                      />
                    )}
                    <AdverStyle.BannerBlock>
                      <AdverStyle.BannerInfoWrapper>
                        <AdverStyle.TextBlock>
                          <AdverStyle.BannerTitle>
                            {adver.title}
                          </AdverStyle.BannerTitle>
                          <AdverStyle.BannerDesc>
                            {adver.description}
                          </AdverStyle.BannerDesc>
                        </AdverStyle.TextBlock>
                        <Redirect to={adver.imageLink}>
                          <VisitButton size="lg">Visit</VisitButton>
                        </Redirect>
                      </AdverStyle.BannerInfoWrapper>
                    </AdverStyle.BannerBlock>
                  </AdverStyle.BannerImageBlock>
                </AdverStyle.BannerWrapper>
              ))}
        </AdverStyle.BannerSection>
      ) : (
        <Style.BannerContainer>
          {!!mainBannerList.length && (
            <Style.BannerSection isFinalLoading={isFinalLoading}>
              {BannersData.filter((data) => data.sort === currentSlide).map(
                (info, idx) => (
                  <Style.MainHeaderBlock
                    key={`main-banner-${idx}`}
                    isAdvertise={isAdvertise}
                    bannerBackground={info.backgroundColor as string}
                    custom={{
                      delay: 0.1,
                      y: -150,
                    }}
                    animate={
                      slideInViewList[currentSlide - 1] ? 'visible' : 'hidden'
                    }
                    variants={variants}
                  />
                ),
              )}

              {isFinalLoading ? (
                <Style.BannerTextBlock>
                  <Skeleton
                    width="100%"
                    height="67"
                    borderRadius="12px"
                    marginBottom={4}
                  />
                  <Skeleton
                    width="100%"
                    height="30"
                    borderRadius="12px"
                    marginBottom={4}
                  />
                  <Style.BannerButtonBlock>
                    <Skeleton width="50%" height="100%" borderRadius="12px" />
                  </Style.BannerButtonBlock>
                </Style.BannerTextBlock>
              ) : (
                !isMdDownScreen &&
                BannersData.filter((data) => data.sort === currentSlide).map(
                  (info, idx) => (
                    <Style.BannerTextContainer key={`normalBanner-${idx}`}>
                      <Style.BannerTextBlock
                        layout
                        custom={{
                          delay: 0.1,
                          y: 0,
                        }}
                        animate={
                          slideInViewList[currentSlide - 1]
                            ? 'visible'
                            : 'hidden'
                        }
                      >
                        <Style.BannerTitle>
                          <motion.p
                            custom={{
                              delay: 0.1,
                              y: 0,
                            }}
                            animate={
                              slideInViewList[currentSlide - 1]
                                ? 'visible'
                                : 'hidden'
                            }
                            variants={variants}
                          >
                            {info.title}
                          </motion.p>
                        </Style.BannerTitle>
                        <Style.BannerDescription>
                          <motion.p
                            custom={{
                              delay: 0.1,
                              y: 0,
                            }}
                            animate={
                              slideInViewList[currentSlide - 1]
                                ? 'visible'
                                : 'hidden'
                            }
                            variants={variants}
                          >
                            {info.description}
                          </motion.p>
                        </Style.BannerDescription>
                      </Style.BannerTextBlock>

                      {info.imageLink && info.buttonName && (
                        <Style.BannerButtonBlock
                          layout
                          custom={{
                            delay: 0.2,
                            x: 0,
                            y: 0,
                          }}
                        >
                          <Redirect to={info.imageLink}>
                            <Style.BannerLinkButton
                              layout
                              custom={{
                                x: 0,
                              }}
                              animate={
                                slideInViewList[currentSlide - 1]
                                  ? 'visible'
                                  : 'hidden'
                              }
                            >
                              {capitalize(info.buttonName)}
                            </Style.BannerLinkButton>
                          </Redirect>
                        </Style.BannerButtonBlock>
                      )}
                    </Style.BannerTextContainer>
                  ),
                )
              )}

              <Style.MainBanner
                onMouseEnter={handleMouseOver(true)}
                onMouseLeave={handleMouseOver(false)}
              >
                {isFinalLoading ? (
                  <Skeleton
                    width="100%"
                    height={isMdDownScreen ? '300' : '100%'}
                    borderRadius="12px"
                    marginBottom={4}
                  />
                ) : (
                  <>
                    <CustomSwiper
                      options={mainSlideOptions}
                      onSlideChange={(swiper) => {
                        setSlideInViewList(mainBannerList.map(() => false));
                        setTimeout(() => {
                          setCurrentSlide(swiper.realIndex + 1);
                        }, 300);
                      }}
                    >
                      {mainBannerList.map((item, idx) => (
                        <CustomSwiper.Item
                          key={`main-banner-${idx}-${item.bannerId}`}
                          className={`banner-slide-${item.bannerId}`}
                        >
                          <Style.BannerImageBlock
                            backgroundColor={item.backgroundColor}
                          >
                            {item.contentType.toLowerCase() === 'video' && (
                              <Video
                                src={item.image}
                                poster={item.thumbnailImage}
                                errorImg={item.thumbnailImage}
                                autoPlay
                                loop
                                muted
                                playsInline
                                isPlaydapp={false}
                                preload="metadata"
                                isObjectFitCover
                              />
                            )}
                            {item.contentType.toLowerCase() === 'image' && (
                              <Image
                                src={item.image}
                                alt={item.title}
                                layout="fill"
                                objectFit="cover"
                                objectPosition="center"
                                quality={100}
                                priority
                                draggable={false}
                              />
                            )}
                          </Style.BannerImageBlock>
                        </CustomSwiper.Item>
                      ))}

                      {isMdDownScreen &&
                        BannersData.filter(
                          (data) => data.sort === currentSlide,
                        ).map((info, idx) => (
                          <Style.BannerTextContainer
                            key={`mdDownBanner-${idx}`}
                          >
                            <Style.BannerTextBlock
                              layout
                              custom={{
                                delay: 0.1,
                                y: 0,
                              }}
                              animate={
                                slideInViewList[currentSlide - 1]
                                  ? 'visible'
                                  : 'hidden'
                              }
                            >
                              <Style.BannerTitle>
                                <motion.p
                                  custom={{
                                    delay: 0.1,
                                    y: 0,
                                  }}
                                  animate={
                                    slideInViewList[currentSlide - 1]
                                      ? 'visible'
                                      : 'hidden'
                                  }
                                  variants={variants}
                                >
                                  {info.title}
                                </motion.p>
                              </Style.BannerTitle>
                              <Style.BannerDescription>
                                <motion.p
                                  custom={{
                                    delay: 0.1,
                                    y: 0,
                                  }}
                                  animate={
                                    slideInViewList[currentSlide - 1]
                                      ? 'visible'
                                      : 'hidden'
                                  }
                                  variants={variants}
                                >
                                  {info.description}
                                </motion.p>
                              </Style.BannerDescription>
                            </Style.BannerTextBlock>
                            {info.imageLink && info.buttonName && (
                              <Style.BannerButtonBlock
                                layout
                                custom={{
                                  delay: 0.2,
                                  x: 0,
                                  y: 0,
                                }}
                              >
                                <Redirect to={info.imageLink}>
                                  <Button size="lg">
                                    {capitalize(info.buttonName)}
                                  </Button>
                                </Redirect>
                              </Style.BannerButtonBlock>
                            )}
                          </Style.BannerTextContainer>
                        ))}
                      <div className="swiper-pagination" />
                    </CustomSwiper>

                    <SwiperNavigation
                      isNext={false}
                      isBannerOver={isBannerOver}
                      isMdDownScreen={isMdDownScreen}
                    >
                      <div className="swiper-button-prev2">
                        <Icon name="arrow-left-v2" alt="arrow-left" size={12} />
                      </div>
                    </SwiperNavigation>
                    <SwiperNavigation
                      isNext
                      isBannerOver={isBannerOver}
                      isMdDownScreen={isMdDownScreen}
                    >
                      <div className="swiper-button-next2">
                        <Icon
                          name="arrow-right-v2"
                          alt="arrow-right"
                          size={12}
                        />
                      </div>
                    </SwiperNavigation>
                  </>
                )}
              </Style.MainBanner>
            </Style.BannerSection>
          )}
        </Style.BannerContainer>
      )}
    </div>
  );
};

export default HomeBanner;
