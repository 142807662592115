import styled from '@emotion/styled';

type Props = {
  bgColor?: string;
};

const BodyBlock = styled.div<Props>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) => props.bgColor || '#fafafb'};
  z-index: -1;
  transition: background-color 0.2s ease-in-out;
`;

BodyBlock.defaultProps = {
  bgColor: '#fafafb',
};

export default BodyBlock;
