import Link from 'next/link';
import { useMedia } from 'react-use';
import styled from '@emotion/styled';
import {
  palette,
  spacing,
  Typography,
  Button,
  breakpoints,
  fontStyle,
} from '@playdapp/ui';

import { getBreakpointQuery } from 'lib/util';

import Image from './Image';

const Container = styled.section`
  padding: ${spacing.xxxl} 6rem;
  background-color: ${palette.primary200};
  text-align: center;

  ${breakpoints.down('md')} {
    padding: ${({ theme }) => `${spacing.xl} ${theme.rem(50)}`};
  }
`;

const ContentBlock = styled.div`
  position: relative;
  width: ${({ theme }) => theme.rem(616)};
  margin: auto;

  ${breakpoints.down('md')} {
    width: 100%;
  }
`;

const Title = styled.h3`
  ${fontStyle('h3')};
  margin-bottom: ${spacing.m};
  color: ${palette.primary600};

  ${breakpoints.down('lg')} {
    ${fontStyle('h4')};
  }

  ${breakpoints.down('md')} {
    ${fontStyle('h6')};
    margin-bottom: ${spacing.xs};
  }
`;

const Description = styled.div`
  position: relative;
  font-weight: 400;
  font-size: 22px;
  margin-bottom: ${spacing.xxl};
  z-index: 1;

  ${breakpoints.down('lg')} {
    ${fontStyle('p2')};
  }

  ${breakpoints.down('md')} {
    display: block;
    ${fontStyle('p5')};
    margin: 0 auto ${spacing.xl} auto;
  }
`;

const MessageIcon = styled.figure`
  position: absolute;
  max-width: 130px;
  top: -32px;
  left: -100px;

  ${breakpoints.down('lg')} {
    max-width: 100px;
    top: -24px;
    left: -95px;
  }

  ${breakpoints.down('md')} {
    display: none;
  }
`;

const CoinIcon = styled.figure`
  position: absolute;
  max-width: 150px;
  bottom: -32px;
  right: -89px;

  ${breakpoints.down('lg')} {
    max-width: 120px;
    right: -44px;
  }

  ${breakpoints.down('md')} {
    display: none;
  }
`;

const RegistButton = styled(Button)`
  width: ${({ theme }) => theme.rem(360)};

  ${breakpoints.down('md')} {
    width: ${({ theme }) => theme.rem(148)};
  }
`;

const RegisterStorefront = () => {
  const isMdDown = useMedia(getBreakpointQuery(breakpoints.down('md')), false);

  return (
    <Container>
      <ContentBlock>
        <MessageIcon>
          <Image
            prefix="s3"
            src="/storefront/messanger.png"
            width="100%"
            height="100%"
            layout="intrinsic"
            alt="store-message"
          />
        </MessageIcon>

        <Title>Launch your own marketplace</Title>

        <Description>
          Register to PlayDapp’s Storefront to become our partner.
        </Description>

        <Link href="/storefront" passHref>
          <RegistButton size={isMdDown ? 'sm' : 'md'}>
            <Typography type={isMdDown ? 'b3' : 'b1'} color="atlantic">
              To Storefront
            </Typography>
          </RegistButton>
        </Link>

        <CoinIcon>
          <Image
            prefix="s3"
            src="/storefront/coin.png"
            width="100%"
            height="100%"
            layout="intrinsic"
            alt="store-coin"
          />
        </CoinIcon>
      </ContentBlock>
    </Container>
  );
};

export default RegisterStorefront;
