import { VideoHTMLAttributes, useEffect, useState, useRef } from 'react';
import styled from '@emotion/styled';
import Image from './Image';

type Props = Omit<VideoHTMLAttributes<HTMLVideoElement>, 'src'> & {
  src: string;
  isPlaydapp?: boolean;
  errorImg?: string;
  isObjectFitCover?: boolean;
};

const StyledVideo = styled.video<{ isObjectFitCover?: boolean }>`
  width: 100% !important;
  height: 100% !important;
  object-fit: ${({ isObjectFitCover }) =>
    isObjectFitCover ? 'cover !important' : 'inherit !important'};
`;

const host = `https://images.playdapp.com/marketplace${
  process.env.NEXT_PUBLIC_ENV === 'development' ? '-dev' : ''
}`;

const Video = ({
  isPlaydapp = true,
  src,
  errorImg,
  isObjectFitCover = true,
  ...rest
}: Props) => {
  const [isError, setIsError] = useState(false);

  const videoElement = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (videoElement?.current) {
      videoElement.current.onerror = () => {
        setIsError(true);
      };
    }
  }, []);

  if (isError)
    return (
      <Image
        src={
          errorImg ||
          'https://images.playdapp.com/marketplace-dev/collections/placeholder2v.png'
        }
        alt="video"
        layout="fill"
        objectFit="cover"
      />
    );

  return (
    <StyledVideo
      ref={videoElement}
      isObjectFitCover={isObjectFitCover}
      {...rest}
    >
      <source src={isPlaydapp ? `${host}${src}` : src} type="video/mp4" />
    </StyledVideo>
  );
};

export default Video;
