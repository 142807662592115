import SwiperCore, {
  Navigation,
  Autoplay,
  Pagination,
  Swiper as SwiperClass,
  EffectFade,
} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import SwiperProps from 'types/swiper';
import sliderOptions from 'data/sliderOptions';

import 'swiper/css';

SwiperCore.use([Navigation, Autoplay, Pagination, EffectFade]);

type Props = {
  children: React.ReactNode;
  options?: SwiperProps;
  onMouseEnter?: (swiper: SwiperClass) => void;
  onMouseLeave?: (swiper: SwiperClass) => void;
  onSlideChange?: (swiper: SwiperClass) => void;
};

const CustomSwiper = ({
  children,
  options = sliderOptions,
  onMouseEnter,
  onMouseLeave,
  onSlideChange,
}: Props) => (
  <Swiper
    {...options}
    onAutoplayResume={onMouseLeave}
    onAutoplayPause={onMouseEnter}
    onSlideChange={onSlideChange}
  >
    {children}
  </Swiper>
);

CustomSwiper.Item = SwiperSlide;

export default CustomSwiper;
