import { useCallback, useEffect, useMemo, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { useMedia } from 'react-use';
import { breakpoints, Button } from '@playdapp/ui';
import { useQuery, UseQueryResult } from 'react-query';
import times from 'lodash/times';

import { ResponseError } from 'api/error';
import { getHome } from 'api/home';
import { getOrderCurrency } from 'api/order';
import { useAppDispatch } from 'store/hooks';
import { setIsSelectAdvertise } from 'store/advertise';
import {
  setOrOperations,
  setPrevUrl,
  setSelectedSortByTypeFilter,
} from 'store/filters';
import connectorList from 'lib/connectors';
import {
  checkMobileDevice,
  getBreakpointQuery,
  numberToAbbreviateString,
  toUSD,
} from 'lib/util';
import useWindowWidth from 'hooks/useWindowWidth';
import useWeb3Info from 'hooks/useWeb3Info';
import { gettingStartedItems } from 'data/home';
import viewports from 'data/viewports';

import type ResponseResult from 'types/response';
import type { Banner, Collection, HomeResponseData } from 'types/home';
import type { NetworkName } from 'types/network';
import type { Order } from 'types/order';

import * as Style from 'styles/pages/home';

import ErrorContents from '@/components/ErrorContents';
import BodyBlock from '@/components/common/BodyBlock';
import HomeBanner from '@/components/HomeBanner';
// import HomeDrops from '@/components/HomeDrops2';
import HomeCollections from '@/components/HomeCollections';
import Redirect from '@/components/Redirect';
import Icon from '@/components/Icon';
import Card from '@/components/Card';
import Image from '@/components/Image';
import RegisterStorefront from '@/components/RegisterStorefront';

type Currency = {
  chainlinkAdress: string;
  contractAddress: string;
  currencyId: number;
  currencyName: string;
  currencyType: string;
  decimals: number;
  imageUrl: string;
  network: string;
  symbol: string;
  toUsd: number;
};

type Feature =
  | number
  | {
      tokenid: string;
      title: string;
      image: string;
      auctionExpireAt: number;
      isBundle: boolean;
      like: number;
      order: Order;
      label: string;
      assetAddress: string;
      network: string;
      collectionInfo: Collection;
      exChange: string;
    };

const getCardCount = (arr: Feature[], width: number) => {
  let endIdx = 18;

  if (width >= viewports.EXTRA_LARGE && width < 1600) {
    endIdx = 15;
  } else if (width >= viewports.LARGE && width < viewports.EXTRA_LARGE) {
    endIdx = 16;
  }

  return arr.slice(0, endIdx);
};

const MainContent = () => {
  const [mainBannerList, setMainBannerList] = useState<Banner[]>([]);
  const [topCollections, setTopCollections] = useState<Collection[]>([]);
  const [features, setFeatures] = useState<Feature[]>([]);
  const [isParseLoading, setIsParseLoading] = useState(true);
  const [isParseError, setIsParseError] = useState(false);
  const [isAdvertise, setIsAdvertise] = useState(false);

  const {
    activate,

    // account, library, chainId
  } = useWeb3React<Web3Provider>();

  const { active } = useWeb3Info();

  const [isMobileDeviceMount, setIsMobileDeviceMount] = useState<
    boolean | null
  >(null);

  const router = useRouter();
  const dispatch = useAppDispatch();

  const isMdDownScreen = useMedia(
    getBreakpointQuery(breakpoints.down('md')),
    true,
  );
  const {
    isFetching,
    error,
    data: response,
  }: UseQueryResult<ResponseResult<HomeResponseData>, ResponseError> = useQuery(
    'home',
    () => getHome(),
    { refetchOnWindowFocus: false },
  );

  const { width, handleResize } = useWindowWidth(300);

  const isFinalLoading = useMemo(
    () => isFetching || isParseLoading,
    [isFetching, isParseLoading],
  );

  const handleDataParsing = useCallback(async () => {
    if (!response || !response.data) {
      if (!isFetching) {
        setIsParseLoading(false);
      }
      return;
    }

    try {
      const { data: currencyList } = await getOrderCurrency('erc20');

      const { data } = response;
      const { banners, topCollections: topCollectionsData, featured } = data;

      setMainBannerList(banners.filter((item: Banner) => item.type === 'main'));
      setTopCollections(topCollectionsData);

      if (banners.length) {
        const bannerIsAd = banners.map((info) => info.isAd);
        if (bannerIsAd.includes('Y')) {
          setIsAdvertise(true);
          dispatch(setIsSelectAdvertise(true));
        } else {
          setIsAdvertise(false);
          dispatch(setIsSelectAdvertise(false));
        }
      }

      if (featured.length) {
        const featureList: Feature[] = featured.map((item) => {
          const { isInBundle, order, network, assetAddress, collectionInfo } =
            item;

          let exChange = '';

          if (
            order?.sale?.price &&
            order?.sale?.currency &&
            network &&
            currencyList
          ) {
            const findCurrency = currencyList.find(
              (item2: Currency) =>
                item2.symbol === order.sale.currency &&
                item2.network === network,
            );

            if (findCurrency)
              exChange = toUSD(order.sale.price, findCurrency.toUsd);
          }

          return {
            tokenid: item.tokenId,
            title: item.name,
            image: item.imageUri,
            like: item.likeCount,
            auctionExpireAt: order?.auction?.expireAt ?? 0,
            order,
            isBundle: !!isInBundle,
            label: item.assetTitle,
            assetAddress,
            network,
            collectionInfo,
            exChange,
          };
        });

        setFeatures(getCardCount(featureList, width));
      }

      setIsParseLoading(false);
    } catch (e) {
      setIsParseError(false);
      setIsParseLoading(false);
    }
  }, [response, width, isFetching]);

  useEffect(() => {
    handleResize();
  }, [handleResize]);

  useEffect(() => {
    if (checkMobileDevice()) {
      setIsMobileDeviceMount(true);
    } else {
      setIsMobileDeviceMount(false);
    }

    return () => {
      setIsMobileDeviceMount(null);
    };
  }, []);

  useEffect(() => {
    if (typeof isMobileDeviceMount === 'boolean' && isMobileDeviceMount) {
      if (
        !active &&
        !localStorage.getItem('PDMP_AUTH_TOKEN') &&
        window.ethereum?.isCoinbaseBrowser
      ) {
        (async () => {
          activate(connectorList.metamask);
        })();
      } else {
        setIsMobileDeviceMount(null);
      }
    }
  }, [isMobileDeviceMount, active, activate]);

  // useEffect(() => {
  //   if (
  //     typeof isMobileDeviceMount === 'boolean' &&
  //     active &&
  //     account &&
  //     library &&
  //     chainId &&
  //     !localStorage.getItem('PDMP_AUTH_TOKEN')
  //   ) {
  //     console.log('main', isMobileDeviceMount, active, account, library);

  //     const provider = window.ethereum?.isCoinbaseBrowser
  //       ? 'walletlink'
  //       : 'metamask';
  //     dispatch(
  //       setWalletInfo({
  //         account,
  //         provider,
  //         activeChainId: activeChainId.ethereum,
  //         library,
  //         chainId,
  //       }),
  //     );
  //     (async () => {
  //       alert('test2');
  //       const sign = await library.send('personal_sign', [
  //         getSignMessage(signMessage(account)),
  //         account,
  //       ]);
  //       await onLogin({
  //         account,
  //         provider,
  //         network: 'ethereum',
  //         sign,
  //         version: AUTH_VERSION,
  //       });
  //       dispatch(setConnector({ network: 'ethereum', library }));
  //       dispatch(readyToUpdate());
  //       setIsMobileDeviceMount(null);
  //     })();
  //   }
  // }, [isMobileDeviceMount, active, account, library, dispatch]);

  useEffect(() => {
    handleDataParsing();
  }, [response, handleDataParsing]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const { pathname } = window.location;

      if (pathname === '/' || window.location.pathname === '/') {
        dispatch(setOrOperations([]));
        dispatch(setSelectedSortByTypeFilter('Recently added'));
      }
    }
  }, [dispatch, router]);

  useEffect(
    () => () => {
      setIsParseLoading(true);
      setIsParseError(false);
    },
    [],
  );

  useEffect(() => {
    dispatch(setPrevUrl('nfts'));
  }, [dispatch]);

  if (error || isParseError || (response && response.status !== 200)) {
    return <ErrorContents />;
  }

  return (
    <>
      <BodyBlock />
      <Style.MainContentBlock>
        <HomeBanner
          isAdvertise={isAdvertise}
          isFinalLoading={isFinalLoading}
          mainBannerList={mainBannerList}
        />

        {/* <Style.DropsSlideSection>
          <HomeDrops />
        </Style.DropsSlideSection> */}

        <Style.CollectionSlideSection>
          <HomeCollections
            collections={topCollections}
            isLoading={isFinalLoading}
          />
        </Style.CollectionSlideSection>

        <Style.BottomContentBlock>
          <Style.FeaturedNftSections>
            <Style.FeatureTitleBlock>
              <Style.SectionTitle>Featured NFTs</Style.SectionTitle>
              <Redirect to="/nfts/all/all">
                <Style.ViewAllBlock>
                  <Button
                    size={isMdDownScreen ? 'sm' : 'md'}
                    variant="ghost"
                    color="secondary"
                  >
                    View all
                    <Icon
                      name="chevron-right-nopadding"
                      size={isMdDownScreen ? '8px' : '12px'}
                    />
                  </Button>
                </Style.ViewAllBlock>
              </Redirect>
            </Style.FeatureTitleBlock>

            <Style.FeaturedNftContainer>
              {isFinalLoading
                ? times(15, Number).map((item) => (
                    <Card key={`card-${item}`} isLoading />
                  ))
                : features.map((item, idx) => {
                    if (typeof item === 'number') return <>&nbsp;</>;
                    if (item.network === 'klaytn') return;
                    return (
                      <Card
                        key={`FeaturedNftCard-${idx}-${item.tokenid}`}
                        collectionInfo={item.collectionInfo}
                        imageUri={item.image}
                        tokenId={item.tokenid}
                        network={item.network as NetworkName}
                        name={item.title}
                        order={item.order}
                        href={`/item/${item.network}/${item.assetAddress}/${item.tokenid}`}
                        convertedPrice={item.exChange}
                        like={numberToAbbreviateString(item.like)}
                      />
                    );
                  })}
            </Style.FeaturedNftContainer>
          </Style.FeaturedNftSections>
        </Style.BottomContentBlock>

        <Style.GettingStartedSection>
          <Style.GettingStartTitle>Getting Started</Style.GettingStartTitle>
          <Style.GettingStartedListBlock>
            {gettingStartedItems.map((item) => (
              <Link
                key={`${item.title}-${item.imageSrc}`}
                href={item.link}
                passHref
              >
                <Style.GettingStartedItemBlock
                  target={item.target}
                  rel="noopener noreferrer"
                >
                  <Style.GettingStartedItemImageBlock>
                    <Image
                      prefix="s3"
                      src={item.imageSrc}
                      layout="responsive"
                      width={455}
                      height={176}
                      alt="bg-getting-start"
                    />
                  </Style.GettingStartedItemImageBlock>
                  <Style.GettingStartedItemTextBlock>
                    <Style.GettingStartedItemText>
                      {item.title}
                    </Style.GettingStartedItemText>
                  </Style.GettingStartedItemTextBlock>
                </Style.GettingStartedItemBlock>
              </Link>
            ))}
          </Style.GettingStartedListBlock>
        </Style.GettingStartedSection>
      </Style.MainContentBlock>

      <RegisterStorefront />
    </>
  );
};

export default MainContent;
